import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails";
import intlTelInput from 'intl-tel-input';
import translations from '../translations';

// Connects to data-controller="phone-numbers"
export default class extends Controller {
  static values = {
    verified: Boolean
  }
  static targets = ["codeField", "phoneField"]

  errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];
  classes = {
    validClass: "border-green-500",
    errorClass: "border-red-500",
    enabledClasses: ["bg-indigo-600", "hover:bg-indigo-700", "focus:ring-indigo-500"],
    disabledClasses: ["disabled", "bg-gray-300", "cursor-not-allowed", "hover:bg-gray-300", "focus:ring-transparent"],
  }

  phoneInput;
  phoneInputField;
  verifyButton;
  valChip;
  codeRequested = false;
  storedPhoneNumber;

  init() {
    this.verifyButton = document.querySelector("#phone-number-submit");
    this.valChip = document.querySelector("#verified-chip");
    this.phoneInputField = document.querySelector("#phone_number");
    this.phoneInput = intlTelInput(this.phoneInputField, {
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.2.1/js/utils.js",
      initialCountry: "auto",
      geoIpLookup: this.getIp,
      preferredCountries: ["ar", "br", "cl", "co", "ec", "pe", "uy"],
    });
    this.phoneInput.promise.then(() => {
      this.checkSameAsVerified();
      this.validatePhoneNumber();
    })
  }

  phoneFieldTargetConnected() {
    this.init();
    this.storedPhoneNumber = this.phoneInputField.value;
    if (this.verifyButton !== null) this.disableButton(this.verifyButton);
  }

  getIp(callback) {
    const ipInfoToken = 'b8350b270a9d85';
    fetch(`https://ipinfo.io/json?token=${ipInfoToken}`, { headers: { 'Accept': 'application/json' } })
      .then((resp) => resp.json())
      .then((resp) => {
        callback(resp.country)
      })
      .catch(() => {
        callback('ar')
      });
  }

  requestVerification(eventId) {
    const url = `/phone_number_verifications`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "text/vnd.turbo-stream.html",
      },
      body: JSON.stringify({
        user: {
          phone_number: this.phoneInput.getNumber(),
        },
        authenticity_token: document.querySelector("[name='authenticity_token']").value,
      }),
    }).then(response => response.text())
      .then(html => {
        Turbo.renderStreamMessage(html);
        this.disableButton(this.verifyButton);
        const msg = "Code sent";
        this.verifyButton.innerHTML = (navigator.languages.includes('es')) ?
          translations['es'][msg] :
          msg;
      });
    this.codeRequested = true;
    this.phoneInputField.disabled = true;
  }

  validatePhoneNumber(e) {
    if (e !== undefined) e.preventDefault();
    if (this.codeRequested) return;
    if (this.phoneInput.getNumber() === '') return;

    const errorMsg = document.querySelector("#phone-info-error");

    if (this.phoneInputField.value.trim().length > 7) {
      if (this.phoneInput.isValidNumber()) {
        this.phoneInputField.classList.remove(this.classes.errorClass);
        this.phoneInputField.classList.add(this.classes.validClass);
        if (this.verifyButton !== null) this.enableButton(this.verifyButton);
        errorMsg.style.display = "none";
      } else {
        this.phoneInputField.classList.remove(this.classes.validClass);
        this.phoneInputField.classList.add(this.classes.errorClass);
        if (this.verifyButton !== null) this.disableButton(this.verifyButton);
        this.phoneInputField.classList.add(this.error);
        const errorCode = this.phoneInput.getValidationError();
        if (navigator.languages.includes('es')) {
          errorMsg.innerHTML = translations['es'][this.errorMap[errorCode]];
        } else {
          errorMsg.innerHTML = this.errorMap[errorCode];
        }
        errorMsg.style.display = "inherit";
      }
    } else {
      if (this.verifyButton !== null) this.verifyButton.disabled = true;
    }
  }

  checkSameAsVerified() {
    if (this.phoneInput.getNumber() === this.storedPhoneNumber && this.verifiedValue === true) {
      if (this.valChip !== null) {
        this.valChip.classList.remove("hidden");
        this.valChip.classList.add("block");
      }
      if (this.verifyButton !== null) {
        this.verifyButton.classList.add("hidden");
        this.verifyButton.classList.remove("block");
      }
    } else {
      if (this.valChip !== null) {
        this.valChip.classList.remove("block");
        this.valChip.classList.add("hidden");
      }
      if (this.verifyButton !== null) {
        this.verifyButton.classList.remove("hidden");
        this.verifyButton.classList.add("block");
      }
    }
  }

  cleanCodeForm(e) {
    console.log("cleaning code form");
    this.codeFieldTarget.value = '';
  }

  disableButton(button) {
    button.disabled = true;
    this.classes.enabledClasses.forEach((className) => {
      button.classList.remove(className);
    })
    this.classes.disabledClasses.forEach((className) => {
      button.classList.add(className);
    })
  }

  enableButton(button) {
    button.disabled = false;
    this.classes.disabledClasses.forEach((className) => {
      button.classList.remove(className);
    })
    this.classes.enabledClasses.forEach((className) => {
      button.classList.add(className);
    })
  }

}
