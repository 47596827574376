import { Controller } from "@hotwired/stimulus"
const isWebview = require("is-ua-webview");

// Connects to data-controller="device-webview"
export default class extends Controller {
  static targets = [
    "googleSignInDiv",
    "webviewLoginInfo",
    "userAgentInfo",
    "userAgentIsWebview"
  ]

  connect() {
    if (isWebview(window.navigator.userAgent)) {
      if (this.hasGoogleSignInDivTarget) {
        this.googleSignInDivTarget.classList.add("hidden");
      }
      if (this.hasWebviewLoginInfoTarget) {
        this.webviewLoginInfoTarget.classList.remove("hidden");
      }
    }

    if (this.hasUserAgentInfoTarget) {
      this.userAgentInfoTarget.textContent = window.navigator.userAgent;
    }
    if (this.hasUserAgentIsWebviewTarget) {
      this.userAgentIsWebviewTarget.textContent = isWebview(window.navigator.userAgent) ? "(Webview)" : "(No Webview)";
    }
  }
}
