import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="previews"
export default class extends Controller {
  static targets = ["input", "vidPreview"]

  connect() { }

  preview() {
    const input = this.inputTarget;
    const preview = this.vidPreviewTarget;
    const id = this.getIDfromURL(input.value);
    console.log(`ID: ${id}`);

    preview.src = `https://img.youtube.com/vi/${id}/maxresdefault.jpg`;
  }

  getIDfromURL(url) {
    const arr = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

    if (arr[2] !== undefined) {
      return arr[2].split(/[^0-9a-z_\-]/i)[0];
    }

    return url[0];
  }

}