import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="referrer-assignment-fee"
export default class extends Controller {
  static values = {
    percentage: Number
  }
  static targets = ['feeField']

  connect() {
  }

  // Called when the percentage input changes
  percentageChanged(event) {
    this.feeFieldTarget.value = event.target.value / 100.0;
  }
}
