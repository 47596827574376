export default {
    es: {
        "Code sent": "Código enviado",
        "Invalid country code": "Código de país inválido",
        "Invalid number": "Número inválido",
        "Too long": "Número demasiado largo",
        "Too short": "Número demasiado corto",
    },
    en: {
    }
}