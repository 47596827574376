import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="price-multiplier"
export default class extends Controller {
  static targets = ["price", "delta", "output"]

  connect() {
    this.update();
  }

  update() {
    if (!this.hasPriceTarget || !this.hasDeltaTarget) return;

    let price = parseFloat(this.priceTarget.value);
    const delta = parseFloat(this.deltaTarget.value);
    price = price * (1.0 + delta / 100.0);
    if (isNaN(price)) {
      price = 0.0;
    }
    this.outputTarget.innerHTML = price.toLocaleString('es-AR', {
      maximumFractionDigits: 2
    });
  }
}
