// import { Controller } from "@hotwired/stimulus"
import { DetectDirtyFormController } from "stimulus-library";

// Connects to data-controller="dirty-forms"
export default class extends DetectDirtyFormController {
  static targets = ["form", "button"];

  connect() {
    super.connect();
  }

  updateSubmitStatus() {
    if (!!this.formTarget.dataset.dirty) {
      this.buttonTarget.disabled = false;
    } else {
      this.buttonTarget.disabled = true;
    }
  }

  dirtied(event) {
    this.updateSubmitStatus();
  }

  cleaned(event) {
    this.updateSubmitStatus();
  }


}
