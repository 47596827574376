// import { Controller } from "@hotwired/stimulus"
import PlacesAutocomplete from 'stimulus-places-autocomplete'

export default class extends PlacesAutocomplete {
  static targets = ['complete', 'streetNumber', 'route', 'city', 'country', 'latitude', 'longitude']

  connect() {
    super.connect()

    // The google.maps.places.Autocomplete instance.
    this.autocomplete
  }

  // You can override the `initAutocomplete` method here.
  // initAutocomplete() {
  //   super.initAutocomplete()
  // }

  // You can override the `placeChanged` method here.
  // placeChanged() {
  //   console.log('place changed');
  //   super.placeChanged()
  // }

  setAddressComponents(address) {
    super.setAddressComponents(address);

    if (this.hasStreetNumberTarget && this.hasRouteTarget &&
      this.hasCityTarget && this.hasCountryTarget) {
      this.completeTarget.classList.remove('hidden');
    }
  }

  setGeometry(geometry) {
    super.setGeometry(geometry);

    if (this.hasLongitudeTarget && this.hasLatitudeTarget) {
      this.completeTarget.classList.remove('hidden');
    }
  }

  // goneOut() not actually doing anything, because the fields are not being cleared.
  goneOut() {
    if (!(this.streetNumberTarget.value && this.routeTarget.value && this.cityTarget.value && this.countryTarget.value) && !(this.latitudeTarget.value && this.longitudeTarget.value)) {
      this.completeTarget.classList.add('hidden');
    }
  }

  // You can set the Autocomplete options in this getter.
  // get autocompleteOptions() {
  //   return {
  //     fields: ['address_components', 'geometry'],
  //   }
  // }
}
