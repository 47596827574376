import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="total-checkout-amount"
export default class extends Controller {
  static targets = ["total", "numOfTickets", "message"]
  static values = {
    prices: Object,
    messages: Object
  }

  connect() {
    const quantity = this.numOfTicketsTarget.value;
    const totalAmount = this.pricesValue[quantity];
    this.totalTarget.innerHTML = totalAmount.toLocaleString('es-AR', {
      // style: 'currency',
      // currency: '$',
      maximumFractionDigits: 2
    });
    const message = this.messagesValue[quantity];
    this.messageTarget.innerHTML = message;
  }

  updateTotal(event) {
    if (event.target.value !== "") {
      const quantity = event.target.value;
      const totalAmount = this.pricesValue[quantity];
      this.totalTarget.innerHTML = totalAmount.toLocaleString('es-AR', {
        // style: 'currency',
        // currency: '$',
        maximumFractionDigits: 2
      });
      const message = this.messagesValue[quantity];
      this.messageTarget.innerHTML = message;
    }
  }


}
