import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails";
import { BrowserQRCodeReader, BrowserPDF417Reader, BrowserCodeReader } from '@zxing/browser';

// Connects to data-controller="qr-reader"
export default class extends Controller {
  static values = {
    eventId: String
  }

  connect() { }

  closePreview(e) {
    console.log('Closing preview')
    document.querySelector('#video-preview-container').classList.add('hidden');
  }

  fetchTicketData(eventId, passcode) {
    const url = `/gatekeeper/events/${eventId}/ticket_by_passcode/${passcode}`;
    fetch(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      }
    }).then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
  }

  readPDF417(country, text) {
    if (country !== "Argentina") {
      return { full_name: undefined, nid: undefined, error: "País no reconocido" };
    }
    return this.readArgentinaPDF417(text);
  }

  readArgentinaPDF417(text) {
    const parsed = text.split("@");
    if (parsed.length < 5) {
      return { full_name: undefined, nid: undefined, error: "Datos insuficientes en DNI" };
    }
    const full_name = parsed[1] + ' ' + parsed[2];
    const nid = parsed[4];
    return { full_name, nid, error: undefined };
  }

  searchInGuestList(eventId, full_name, nid) {
    let url = `/gatekeeper/events/${eventId}/external_guests_by_full_name_or_nid`;
    url += `?full_name=${full_name}&nid=${nid}`;
    fetch(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      }
    }).then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html));
  }

  async startNidScan(e) {
    e.preventDefault();
    let gotResult = false;
    const codeReader = new BrowserPDF417Reader();

    const errorElement = document.querySelector('#error');
    errorElement.textContent = "";
    errorElement.classList.add('hidden');
    document.querySelector('#result').classList.add('hidden');
    document.querySelector('#result-label').classList.add('hidden');
    const previewElem = document.querySelector('#video-preview');
    document.querySelector('#video-preview-container').classList.remove('hidden');

    const controls = await codeReader.decodeFromVideoDevice(undefined, previewElem, (result, error, controls) => {
      if (result !== undefined) {
        document.querySelector('#result').textContent = result.text;
        document.querySelector('#result').classList.remove('hidden');
        document.querySelector('#result-label').classList.remove('hidden');
        document.querySelector('#video-preview-container').classList.add('hidden');
        document.querySelector('#event-stats').classList.add('hidden');

        gotResult = true;
        controls.stop();
        const { full_name, nid, error } = this.readPDF417("Argentina", result.text);
        if (full_name !== undefined && nid !== undefined && error === undefined) {
          this.searchInGuestList(this.eventIdValue, full_name, nid);
        }
        else {
          document.querySelector('#error').textContent = error;
        }
      }
      if (error !== undefined)
        document.querySelector('#error').textContent = error.text;
    });

    setTimeout(() => {
      if (!gotResult) {
        const errorElement = document.querySelector('#error');
        errorElement.textContent = "No se pudo leer un código DNI";
        errorElement.classList.remove('hidden');
        controls.stop();
        document.querySelector('#video-preview-container').classList.add('hidden');

      }
    }, 15000);
  }

  async startQrScan(e) {
    e.preventDefault();
    let gotResult = false;
    const codeReader = new BrowserQRCodeReader();

    const errorElement = document.querySelector('#error');
    errorElement.textContent = "";
    errorElement.classList.add('hidden');
    document.querySelector('#result').classList.add('hidden');
    document.querySelector('#result-label').classList.add('hidden');

    const previewElem = document.querySelector('#video-preview');
    document.querySelector('#video-preview-container').classList.remove('hidden');

    // you can use the controls to stop() the scan or switchTorch() if available
    const controls = await codeReader.decodeFromVideoDevice(undefined, previewElem, (result, error, controls) => {
      if (result !== undefined) {
        document.querySelector('#result').textContent = result.text;
        document.querySelector('#result-label').classList.remove('hidden');
        document.querySelector('#video-preview-container').classList.add('hidden');
        document.querySelector('#event-stats').classList.add('hidden');
        document.querySelector('#qr-confirmation').classList.remove('hidden');
        gotResult = true;
        controls.stop();

        this.fetchTicketData(this.eventIdValue, result.text);
      }
      if (error !== undefined)
        document.querySelector('#error').textContent = error.text;
    });

    setTimeout(() => {
      if (!gotResult) {
        const errorElement = document.querySelector('#error');
        errorElement.textContent = "No se pudo leer un código QR";
        errorElement.classList.remove('hidden');
        controls.stop();
        document.querySelector('#video-preview-container').classList.add('hidden');
      }
    }, 15000);
  }


}
