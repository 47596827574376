import NestedForm from 'stimulus-rails-nested-form'

export default class extends NestedForm {
  static targets = ["button"];

  connect() {
    super.connect()
  }

  add(event) {
    if (this.hasButtonTarget) {
      this.buttonTarget.disabled = false;
    }
    super.add(event);
  }

  remove(event) {
    if (this.hasButtonTarget) {
      this.buttonTarget.disabled = false;
    }
    super.remove(event);
  }
}