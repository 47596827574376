import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ticket-type-form"
export default class extends Controller {
  static values = {
    status: String,
    index: Number,
    referrers: Boolean,
    messages: Object
  }
  static targets = ["referrerOnlyCheckbox", "referrersOnlyDiv", "referrersPriceDiv", "referrerPriceDeltaField"]

  connect() {
    this.changeStatus({ target: { value: this.statusValue, data: { index: this.indexValue } } });
    this.changeReferrerConfig({ target: { checked: this.referrersValue } });
  }

  changeStatus(e) {
    const status = e.target.value;
    const index = this.indexValue;
    let errorMsg;
    errorMsg = this.messagesValue[status];
    document.getElementById(`status-info-${index}`).innerHTML = errorMsg;
  }

  changeReferrerConfig(event) {
    const referrerOnlyDiv = this.referrersOnlyDivTarget;
    const referrerPriceDiv = this.referrersPriceDivTarget;
    if (event.target.checked) {
      referrerOnlyDiv.classList.remove("hidden");
      referrerPriceDiv.classList.remove("hidden");
      this.referrerPriceDeltaFieldTarget.required = true;
    }
    else {
      referrerOnlyDiv.classList.add("hidden");
      referrerPriceDiv.classList.add("hidden");
      this.referrerPriceDeltaFieldTarget.required = false;
      this.referrerOnlyCheckboxTarget.checked = false;
    }
  }
}
