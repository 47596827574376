import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pre-built-order-options"
export default class extends Controller {
  static values = {
    options: Object,
  };
  static targets = ["select"];

  connect() {
  }

  changeTicketType(event) {
    const allOptions = this.optionsValue;
    const ticketTypeId = parseInt(event.target.value);
    const newOptions = allOptions[ticketTypeId];
    const selectTag = this.selectTarget;
    selectTag.innerHTML = "";
    for (const key in newOptions) {
      const optionTag = document.createElement("option");
      optionTag.value = newOptions[key];
      optionTag.innerHTML = key;
      selectTag.appendChild(optionTag);
    };
  }
}
