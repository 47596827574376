import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="mp-wallet-bricks"
export default class extends Controller {
  static values = {
    mppk: String,
    saved: Boolean
  }

  connect() {
    const savedOrder = this.savedValue;
    savedOrder ?
      this.initMPWalletBrickForSavedOrder() :
      this.initMPWalletBrick();

    window.addEventListener("beforeunload", (event) => {
      window.walletBrickController.unmount()
    });
    document.addEventListener('turbo:before-visit', (event) => {
      window.walletBrickController.unmount()
    });
  }

  initMPWalletBrickForSavedOrder() {
    const mpPublicKey = this.mppkValue;
    const mp = new MercadoPago(
      mpPublicKey,
      {
        locale: 'es-AR'
      });
    const bricksBuilder = mp.bricks();
    const renderWalletBrick = async (bricksBuilder) => {
      const settings = {
        initialization: {
          redirectMode: 'self',
          marketplace: true,
        },
        customization: {
          paymentMethods: {
            // ticket: "all",
            creditCard: "all",
            debitCard: "all",
            mercadoPago: "all",
          },
          texts: {
            action: 'pay',
            valueProp: 'security_safety',
          },
          visual: {
            hideValueProp: false,
            buttonBackground: 'default', // default, black, blue, white
            valuePropColor: 'grey', // grey, white
            buttonHeight: '48px', // min 48px - max free
            borderRadius: '6px',
            verticalPadding: '16px', // min 16px - max free
            horizontalPadding: '0px', // min 0px - max free
          },
          checkout: {
            theme: {
              elementsColor: '#4287F5', // color hex code
              headerColor: '#4287F5', // color hex code
            },
          },
        },
        callbacks: {
          onReady: () => {
            /*
            Callback called when Brick is ready.
            Here you can hide loadings from your site, for example.
            */
          },
          onSubmit: (formData) => {
            /*
            Callback called when clicking Wallet Brick
            this is possible because the brick is a button
            at this time of submit, you must create the preference
            */
            const order_id = document.querySelector('#order_id').value;
            const requestBody = {
              order_id: order_id
            };
            return new Promise((resolve, reject) => {
              fetch('/mercadopago_payments/preferences_for_saved_order', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
              })
                .then((response) => response.json())
                .then((response) => {
                  // resolve the promise with the ID of the preference
                  resolve(response.preference_id);
                })
                .catch((error) => {
                  // handle error response when trying to create preference
                  reject();
                });
            });
          },
        },
      };
      window.walletBrickController = await bricksBuilder.create(
        'wallet',
        'walletBrick_container',
        settings,
      );
    };
    renderWalletBrick(bricksBuilder);
  }

  initMPWalletBrick() {
    const mpPublicKey = this.mppkValue;
    const mp = new MercadoPago(
      mpPublicKey,
      {
        locale: 'es-AR'
      });
    const bricksBuilder = mp.bricks();
    const renderWalletBrick = async (bricksBuilder) => {
      const settings = {
        initialization: {
          redirectMode: 'self',
          marketplace: true,
        },
        customization: {
          paymentMethods: {
            // ticket: "all",
            creditCard: "all",
            debitCard: "all",
            mercadoPago: "all",
          },
          texts: {
            action: 'pay',
            valueProp: 'security_safety',
          },
          visual: {
            hideValueProp: false,
            buttonBackground: 'default', // default, black, blue, white
            valuePropColor: 'grey', // grey, white
            buttonHeight: '48px', // min 48px - max free
            borderRadius: '6px',
            verticalPadding: '16px', // min 16px - max free
            horizontalPadding: '0px', // min 0px - max free
          },
          checkout: {
            theme: {
              elementsColor: '#4287F5', // color hex code
              headerColor: '#4287F5', // color hex code
            },
          },
        },
        callbacks: {
          onReady: () => {
            /*
            Callback called when Brick is ready.
            Here you can hide loadings from your site, for example.
            */
          },
          onSubmit: (formData) => {
            /*
            Callback called when clicking Wallet Brick
            this is possible because the brick is a button
            at this time of submit, you must create the preference
            */
            const num_of_tickets = document.querySelector('#num_of_tickets').value;
            const ticket_type_id = document.querySelector('#ticket_type_id').value;
            const referrer_id = document.querySelector('#referrer_id').value;
            const requestBody = {
              num_of_tickets: num_of_tickets,
              ticket_type_id: ticket_type_id,
              referrer_id: referrer_id
            };
            return new Promise((resolve, reject) => {
              fetch('/mercadopago_payments/preferences', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
              })
                .then((response) => response.json())
                .then((response) => {
                  // resolve the promise with the ID of the preference
                  resolve(response.preference_id);
                })
                .catch((error) => {
                  // handle error response when trying to create preference
                  reject();
                });
            });
          },
        },
      };
      window.walletBrickController = await bricksBuilder.create(
        'wallet',
        'walletBrick_container',
        settings,
      );
    };
    renderWalletBrick(bricksBuilder);
  }
}
